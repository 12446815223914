import React, { useEffect, useState } from 'react';
import '../../styles/archive.css'
import eye from '../../assets/eye.jpg'
import disease from '../../assets/disease.jpg'
import spy from '../../assets/spy.jpg'

const articles = [
    {
      id: 1,
      image: spy,
      title: 'Tech to Prevent Presidential Assassination Attempts',
      summary: "",
      date: 'September 30, 2024',
      link: 'https://tech-fizz.ck.page/posts/tech-to-prevent-presidential-assassination-attempts'
      },
    {
      id: 2,
      image: eye,
      title: 'Google Fights Fire with Space Sensors and Neuralink Promises to Restore Vision',
      summary: '',
      date: 'September 23, 2024',
      link: 'https://tech-fizz.ck.page/posts/google-fights-fire-with-space-sensors-and-neuralink-promises-to-restore-vision'
    },
    {
      id: 3,
      image: disease,
      title: '100% Immunity Against Covid-19 and Brazilian X Will Be Missed',
      summary: '',
      date: 'September 9, 2024',
      link: 'https://tech-fizz.ck.page/posts/100-immunity-against-covid-19-and-brazilian-x-will-be-missed'
    },
];
  
const Archive = () => {
return (
    <div className="archive">
    {articles.map(article => (
        <a href={article.link} key={article.id} className="widget">
        <img src={article.image} alt={article.title} className="widget-image" />
        <div className="widget-content">
            <h2 className="widget-title">{article.title}</h2>
            <p className="widget-summary">{article.summary}</p>
            <p className="widget-date">{article.date}</p>
        </div>
        </a>
    ))}
    <a class="archive-button" href="https://tech-fizz.ck.page/profile">
        View More
    </a>
    </div>
);
};

export default Archive;