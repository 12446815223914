import React, { useRef, useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import '../../styles/subbutton.css';

const FeedbackButton = () => {
    const [apiResponse, setApiResponse] = useState(''); // State to store API response
    const valueRef = useRef('') //creating a refernce for TextField Component

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    // Fetching newsletterId and rating from URL params
    const newsletterId = searchParams.get('newsletterId') || '';
    const rating = searchParams.get('rating') || '';

    useEffect(() => {
        // Clear localStorage on component unmount
        return () => {
          localStorage.removeItem('apiResponse');
        };
      }, []);

    const sendValue = async () => {
        setApiResponse('');
        const requestBody = {
            newsletterId: newsletterId, 
            feedbackRating: rating,
            feedbackText: valueRef.current.value
        };
      
        try {
            const response = await fetch('https://b6h9q4mz69.execute-api.us-west-2.amazonaws.com/prod/feedback', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                // Add any other headers needed for authentication, etc.
              },
              body: JSON.stringify(requestBody),
            });
            window.location.href = '/';
        } catch {
            window.location.href = '/';
        }
    };

    return (
        <div>
          <Box 
          display="flex" 
          justifyContent="center" 
          alignItems="center" 
          height="10vh" // optional, to center vertically as well
          >
            <TextField 
            color='error'
            style={{ width: '80%', maxWidth: '900px'}}
            id="emailInput" 
            minHeight="50vh"
            multiline
            rows={3}
            variant="filled" 
            inputRef={valueRef}  
            sx={{
                textarea: {
                    color: "white",
                    background: "grey"
                },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                        borderColor: 'white',          // Border color of the input
                        },
                        '&:hover fieldset': {
                        borderColor: 'white',          // Border color on hover
                        },  
                        '& .MuiInputLabel-root.Mui-focused': {
                        color: 'white',                 // Color of the label when focused}
                        },
                        '& .MuiInputLabel-root': {
                        color: 'red',                   // Color of the label
                        },
                    }
                }}
            />
          </Box>
          <Box 
                display="flex" 
                justifyContent="center" 
                alignItems="center"
                marginTop="30px" // Adjust this value to position the button lower
            ></Box>
          <div onClick={sendValue} class="subbutton">Submit</div>
        </div>
    )
}

export default FeedbackButton;