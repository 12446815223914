import React, { useRef, useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import { Box } from '@mui/material';
import axios from 'axios';
import '../../styles/subbutton.css';
import { CONVERTKIT_API_KEY } from './apikeys.js'
import { META_TOKEN } from './apikeys.js'
import CryptoJS from 'crypto-js';

const FORM_ID = 6595502;
const META_API_VERSION = 'v18.0'; 

const Subbutton = () => {
    const [apiResponse, setApiResponse] = useState(''); // State to store API response
    const valueRef = useRef('') //creating a refernce for TextField Component
    const [userIp, setUserIp] = useState(''); // State to store user IP address

    // Function to get fbp from cookies
    const getFbpFromCookies = () => {
      const cookies = document.cookie.split('; ');
      const fbpCookie = cookies.find((cookie) => cookie.startsWith('_fbp='));
      return fbpCookie ? fbpCookie.split('=')[1] : null;
    };

    // Fetch the user's IP address when the component mounts
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setUserIp(response.data.ip);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };
    fetchIpAddress();

    useEffect(() => {
        // Clear localStorage on component unmount
        return () => {
          localStorage.removeItem('apiResponse');
        };
      }, []);

    const sendValue = async (event) => {
        event.preventDefault();
        setApiResponse('');
        try {
            const userEmail = valueRef.current.value;
            const response = await axios.post(
                `https://api.convertkit.com/v3/forms/${FORM_ID}/subscribe`,
                {
                  api_key: CONVERTKIT_API_KEY,
                  email: userEmail,
                },
                {
                  headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                  },
                }
              );
            setApiResponse({ message: 'Subscription successful! Please check your email to confirm.', isError: false }); // Set API response in state
            valueRef.current.value = ''; // Clear input after button click

            // Prepare Conversions API data
            const fbcValue = window.location.search.split('fbclid=')[1] || '';
            const fbpValue = getFbpFromCookies() || '';

            const eventData = {
              data: [
                {
                  event_name: "Subscribe",
                  event_time: Math.round(new Date().getTime() / 1000), // Current timestamp in seconds
                  action_source: "website",
                  user_data: {
                    em: [CryptoJS.SHA256(userEmail).toString()], // User email as hashed email (em)
                    client_user_agent: navigator.userAgent,
                    client_ip_address: userIp,
                    fbc: fbcValue,
                    fbp: fbpValue,
                  },
                },
              ],
            };

            // Send conversion event to Facebook using Conversions API
            const pixelResponse = await axios.post(
              `https://graph.facebook.com/${META_API_VERSION}/810322561311414/events`,
              eventData,
              {
                headers: {
                  'Content-Type': 'application/json',
                },
                params: {
                  access_token: META_TOKEN,
                },
              }
            );

            // Track subscribe button click using Meta Pixel
            if (window.fbq) {
              window.fbq('track', 'Subscribe');
            } 

            if (window.SL) {  
              window.SL.trackSubscriber(userEmail); // Track via SparkLoop
            }

          } catch (error) {
            setApiResponse({ message: 'Error: ' + error.response.data.message, isError: true });
          }
    };

    return (
        <div>
          <Box 
          display="flex" 
          justifyContent="center" 
          alignItems="center" 
          height="10vh" // optional, to center vertically as well
          >
            <TextField 
            color='error'
            style={{ width: '300px', margin: 'auto' }}
            id="emailInput" 
            label="Enter Your Email!" 
            variant="filled" 
            inputRef={valueRef}  
            sx={{
                input: {
                    color: "white",
                    background: "grey"
                },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                        borderColor: 'white',          // Border color of the input
                        },
                        '&:hover fieldset': {
                        borderColor: 'white',          // Border color on hover
                        },  
                        '& .MuiInputLabel-root.Mui-focused': {
                        color: 'white',                 // Color of the label when focused}
                        },
                        '& .MuiInputLabel-root': {
                        color: 'red',                   // Color of the label
                        },
                    }
                }}
            />
          </Box>
          <div className={apiResponse.isError ? 'error' : 'success'}>
            {/* Display API response */}
            <p>{apiResponse.message}</p>
          </div>
          <div class="sub-form">
            <form id="form" onSubmit={sendValue}>
              <button type="submit" class="subbutton">Subscribe!</button>
            </form>
          </div>
        </div>
    )
}

export default Subbutton;