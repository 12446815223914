import React, { useEffect } from 'react';
import '../../styles/bubbles.css';

function Bubbles({ id }) {
  const addBubble = () => {
    // Check if the maximum number of bubbles is reached
    if (document.querySelectorAll(`.bubble-container-${id} .bubble`).length >= 200) return;

    const bubble = document.createElement('div');
    bubble.className = 'bubble';

    const size = Math.random() * 20 + 1; // Bubble size between 10px and 30px
    bubble.style.width = `${size}px`;
    bubble.style.height = `${size}px`;

    const animationDuration = Math.random() * 20 + 10; // Duration between 10s and 30s
    bubble.style.animationDuration = `${animationDuration}s`;

    const horizontalPosition = Math.random() * window.innerWidth; // Position between 0 and window width
    const verticalPosition = Math.random() * window.innerHeight;
    bubble.style.left = `${horizontalPosition}px`;
    bubble.style.top = `${verticalPosition}px`; // Start from top left corner of the screen

    document.querySelector(`.bubble-container-${id}`).appendChild(bubble);
  };

  useEffect(() => {
    // Initial bubbles
    const initialBubbles = 40;
    for (let i = 0; i < initialBubbles; i++) {
      addBubble();
    }

    // Add bubbles continuously
    const intervalId = setInterval(addBubble, 50); // Adjust the interval as needed

    // Clean up interval
    return () => clearInterval(intervalId);
  }, []);

  return <div className={`bubbles-container bubble-container-${id}`}></div>;
}

export default Bubbles;