import React from "react";
import '../styles/Home.css';
import Navbar from '../components/navbar';
import Stars from '../components/stars';
import Subbutton from '../components/subbutton';
import Bubbles from '../components/bubbles'; // Import the Bubbles component
import Archive from '../components/archive';
import logo from '../assets/logo.jpeg'
import vlad from '../assets/vlad.png'
import ryan from '../assets/ryan.png'

function Home() {

  const size = {
    width: '100%',
    height: '100%',
  };

  return (
    <div>
      <div class="welcome-area" id="home">
      <Stars></Stars>
      <Navbar></Navbar>
      <Bubbles id="1"></Bubbles>

        <img 
          class="logo"
          src={logo} // Using the imported image as the src
          alt="Logo" 
        />

        <div id="signup">
          <p>The only tech newsletter you'll ever need.</p> 
          <p>New editions every Monday. Subscribe for free.</p>
          <Subbutton></Subbutton>
        </div>
      </div>

      <div class="archive" id="archive">
        <Stars></Stars>
        <Navbar></Navbar>
        <Bubbles id="5"></Bubbles>
        <div class="logo-sub">
            Archive
        </div>
        <Archive></Archive>
      </div>

      <div class="about" id="about">
      <Navbar></Navbar>
      <Stars></Stars>
      <Bubbles id="2"></Bubbles>
        <div class="logo-sub">
            About
        </div>
        <div class="aboutContainer">
        <div class="aboutText">
          <br></br>
          At Tech Fizz, we prioritize breadth and depth. We sift through mainstream news sites and lesser known sources to make sure no gold nuggest of information fall through the cracks. 
          At the same time, we dive deep by exploring how emerging technologies may impact you. 
          <br></br>
          <br></br>
          We believe in <b>quality over quantity</b>. Instead of sending you updates every single day, we take the time to collect and analyze tech stores throughout the week.
          What you see in your inbox is a carefully curated report of developments in the tech ecosystem.
          <br></br>
          <br></br>
            Subscribe to our weekly newsletter, where we pop the top on tech news, one quality story at a time.
          <br></br>
        </div>
        </div>
      </div>

      <div class="theteam" id="theteam">
      <Navbar></Navbar>
      <Stars></Stars>
      <Bubbles id="3"></Bubbles>
      <div class="logo-sub">
            The Team
        </div>
        <div class="teamText">
          <div class="founder">
          <img src={vlad} alt="Vlad" class="founder-img"/>
              <div class="founder-info">
                  <h2>Ukrainian Rocket Scientist | Angel Investor | Multilingual</h2>
                  <h3><i>Vlad, Co-founder at Tech Fizz</i></h3>
                  <p>Leveraging my master’s degree in Aerospace Engineering and bachelor’s degree in Mechanical Engineering, I work as a propulsion engineer in the aerospace industry for my 9-5. Outside of work hours, I enjoy researching emerging technologies and looking for my next investment opportunity.</p>
              </div>
          </div>
          <div class="founder">
          <img src={ryan} alt="Ryan" class="founder-img"/>
              <div class="founder-info">
                  <h2>Big-Tech Software Engineer | World Traveler | Music Wiz</h2>
                  <h3><i>Ryan, Co-founder at Tech Fizz</i></h3>
                  <p>After spending my college years learning and researching everything computers and math, I landed a job in tech and enjoy researching the industry on the side. When I am not coding I enjoy travelling, making EDM and playing the drums. </p>
              </div>
          </div>
        </div>

      </div>

      <div class="more" id="more">
      <Navbar></Navbar>
      <Stars></Stars>
      <Bubbles id="4"></Bubbles>
      
        <div class="logo-sub">
            More
        </div>
        <div>
        <div id="links">
        <a class="link"href="https://www.instagram.com/techfizznews">Instagram</a>
        <a class="link"href="https://www.facebook.com/people/Tech-Fizz/61561084655459">Facebook</a>
        <a class="link"href="mailto:contact@techfizz.news">Contact</a>
        </div>
        </div>

        <div className="custom-footer">
          &copy; 2024 Tech Fizz
        </div>

      </div>

    </div>
  );
}

export default Home;
