import React from "react";
import '../styles/Home.css';
import FeedbackButton from '../components/feedbackbutton';
import Bubbles from '../components/bubbles'; // Import the Bubbles component
import Stars from '../components/stars';

function Home() {

  const size = {
    width: '100%',
    height: '100%',
  };

  return (
    <div>
      <div class="welcome-area" id="home">
      <Stars></Stars>
      <Bubbles id="1"></Bubbles>
        <br></br>
        <br></br>
        <br></br>
        <div class="feedback">
                <p>Thanks for the feedback! Click submit so we can see it! :)</p>
                <p>Anything else to add?</p>
                <FeedbackButton></FeedbackButton>
        </div>
      </div>

    </div>
  );
}

export default Home;
