import React from 'react';
import '../../styles/navbar.css'
  
const Navbar = () => {

    const handleScroll = (event, sectionId) => {
        event.preventDefault();
        document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
      };

  return (
      <div class='nav'>
        <div class='nav-menu'>
            <a href="#home" onClick={(event) => handleScroll(event, 'home')} className="link-text">Home</a>
            <a href="#archive" onClick={(event) => handleScroll(event, 'archive')} className="link-text">Archive</a>
            <a href="#about" onClick={(event) => handleScroll(event, 'about')} className="link-text">About</a>
            <a href="#theteam" onClick={(event) => handleScroll(event, 'theteam')} className="link-text">The Team</a>
            <a href="#more" onClick={(event) => handleScroll(event, 'more')} className="link-text">More</a>
        </div>
      </div>
  );
};
  
export default Navbar;