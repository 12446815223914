import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import Home from './pages/Home';
import Feedback from './pages/Feedback';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// This component will handle page view tracking with Google Analytics
function App() {
  const location = useLocation();

  useEffect(() => {
    const handlePageView = (url) => {
      window.gtag('config', 'G-2C6CWYENMX', {
        page_path: url,
      });
    };

    handlePageView(location.pathname);
  }, [location]);

  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="feedback" element={<Feedback />} />
    </Routes>
  );
}

// Wrap the App component with BrowserRouter in the main rendering
function WrappedApp() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<WrappedApp />);